import { Button } from "@mui/material"

export type Props = {
    isClaimable: boolean
    onClickClaim:any
}
export const ClaimRewardButton = (prop: Props) => {
    return (
        <Button sx= {[ { backgroundColor:"#ff9b05"}, {'&:hover':{backgroundColor:'#ff9b05'}}]} variant="contained" disabled={!prop.isClaimable} onClick={prop.onClickClaim}>Claim Battle Rewards</Button>
    )
}