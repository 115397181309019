import { Box, Card, CardContent, Grid, Typography } from "@mui/material"
import Paper from "@mui/material/Paper"
import { ClaimRewardButton } from "../../components/claim-reward-button"

export type Props = {
    accountAddress: string
    totalClaimableTiny: number
    onClickClaim: any
}
export const ClaimContainer = (prop: Props) => {
    return (
        <>  
            <Grid sx={{ mt: 10, mb: 10 }} container direction="column" alignItems="center">
                <Grid item xs={3}>
                    <Card sx={{ width: 500, backgroundColor: '#1d2125', border: '1px solid #3a3f50'}}>
                        <CardContent>
                            <Grid container alignItems="center">
                                { 
                                prop.accountAddress !== ''?
                                <>
                                    <Grid item xs={6}>
                                        <Typography sx={{ fontSize: 14, color: '#ffffff' }} color="text.secondary" gutterBottom>Claimable: {prop.totalClaimableTiny} TINY</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ClaimRewardButton isClaimable={prop.totalClaimableTiny > 0} onClickClaim={prop.onClickClaim}></ClaimRewardButton>
                                    </Grid>
                                </>
                                :
                                <Grid item xs={12}>
                                    <Typography align="center" sx={{ fontSize: 14, color: '#ffffff' }}>Please Connect Wallet</Typography>
                                </Grid>
                                }
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>)
}