import React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Typography } from '@mui/material'
import { ConnectWalletButton } from '../../components/connect-wallet-button';
export type Props ={
    onClickConnectWallet: () => void,
    connectButtonText: string
}

export const HeaderContainer = (prop: Props) => {
    return (<div>
        <AppBar position="static" sx={{ backgroundColor: '#000000'}}>
            <Toolbar>
                <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
          </IconButton>
          <img src="https://cdn.tinyhero.io/nft/epic/strength/3315933187345.png" height="80px"/>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Tiny Hero Helper
          </Typography>
          <ConnectWalletButton connectButtonText={prop.connectButtonText} onClickConnectWallet={prop.onClickConnectWallet}/>
            </Toolbar>
        </AppBar>
    </div>)
}