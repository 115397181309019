import Button from "@mui/material/Button"

export type Props = {
    onClickConnectWallet: () => void,
    connectButtonText: string
}

export const ConnectWalletButton = (props: Props) => {
    return (
        <Button sx= {[ { backgroundColor:"#ff9b05"}, {'&:hover':{backgroundColor:'#ff9b05'}}]} variant="contained" onClick={props.onClickConnectWallet}>{props.connectButtonText}</Button>
    )
}