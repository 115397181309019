
import { Typography } from "@mui/material"
import image from  "../../resources/images/bmc-button.png"
export const BuyMeCoffee = () => {
    return (
        <div style={{textAlign: "center"}}>
        <img src={image} alt="Logo" height={50} />
        <br/> <br/>
        <Typography sx={{color:'#ffffff'}}>
            Address: 0x6631ee3D0DE0BEA2aD4c1838638b993Ed25e304D
        </Typography>
        </div>
    )
}